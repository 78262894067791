import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../src/translations/en.json';
import prTranslation from "../src/translations/pr.json";
import esTranslation from "../src/translations/es.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      pr: {
        translation: prTranslation
      },
      es: {
        translation: esTranslation
      },
    },
    lng: `${localStorage.getItem("selectedLanguage")}`,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;