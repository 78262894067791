import React, { Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Snackbar from "./ui-component/snackbar";
import "./index.css";
import { AuthRoutes } from "./routes/AuthRoutes";
import useBoxStyles from "./theme/main-layout";
import "./assets/css/style.css"
import AuthContext from "./contexts/auth/auth-context";
import Header from "./views/Header";
import SideNav from "./views/Side-Nav";
import { menus } from "./views/Side-Nav/menus";
import { MainContainer } from "./views/authentication/authentication-base"
import { closeDrawer } from "./store/slices/drawerSlices";
import { useDispatch } from "react-redux";

function App() {
  const { isLoggedIn } = useContext(AuthContext);
  const dispatch = useDispatch();
  const styles = useBoxStyles();
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const storedPermissions = localStorage.getItem('user');
    const userDetails = JSON.parse(storedPermissions)?.user;
    let parsedPermissions = null
    if (storedPermissions) {
      parsedPermissions = JSON.parse(storedPermissions)?.permissions;
      console.log("Parsed Permissions:", parsedPermissions);
    }

    let filteredRoutes = menus?.children?.filter(menuItem => {
      return parsedPermissions?.find(permission => permission.codename === menuItem.id) || menuItem.id === "logout";
    }) || [];
    if (userDetails?.user?.is_client) {
      if (userDetails?.client_type === "admin" && userDetails?.client === null) {
        console.log("Parsed user Deatils:", userDetails);
        setRoutes(menus?.children);
      }
    }
    else {
      setRoutes(filteredRoutes);
    }
    console.log("Filtered Routes:", filteredRoutes);
  }, [menus]);

  console.log("Routes:", routes);

  const renderAuthRoutes = () => {
    if (isLoggedIn()) {
      dispatch(closeDrawer());
    }
    return (
      AuthRoutes?.map((item, index) => {
        return (
          <Route key={item.id} path={item.path} element={item.element}>
            {item.children && item.children?.map(child => {
              return <Route key={item.id} path={child.path} element={child.element} />
            })}
          </Route>
        )
      })
    )
  }

  return (
    <Router>
      <Suspense>
        {isLoggedIn() && (window?.location?.pathname !== "/login" || window?.location?.pathname !== "/") ? (
          <>
            <SideNav />
            <Header />
            <Box sx={styles.box}>
              <Routes>
                {
                  routes?.map((item, index) => {
                    return (
                      <>
                        <Route key={item.url} path={item.url} element={item.element}>
                        </Route>
                        {
                          item.children && item.children.map(child => {
                            return <Route key={item.url} path={child.url} element={child.element} />
                          })
                        }
                        {(window?.location?.pathname === "*" || window?.location?.pathname === "/") &&
                          renderAuthRoutes()
                        }
                      </>)
                  })
                }
              </Routes>
            </Box></>
        ) : (
          <Box component="main" sx={{ flexGrow: 1, p: 0, marginTop: '0px', }}>
            <MainContainer>
              <Routes>
                {
                  AuthRoutes?.map((item, index) => {
                    return (
                      <Route key={item.id} path={item.path} element={item.element}>
                        {item.children && item.children?.map(child => {
                          return <Route key={item.id} path={child.path} element={child.element} />
                        })}
                      </Route>
                    )
                  })
                }
              </Routes>
            </MainContainer>
          </Box>
        )}
      </Suspense>
      <Snackbar />
    </Router>
  );
}

export default App;

