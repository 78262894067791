import { useEffect, useState } from "react";
import React from "react";
import InputMask from "react-input-mask";
import {
  Autocomplete,
  MenuItem,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  FormControl,
  Grid,
  Checkbox,
  FormGroup,
  InputAdornment,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import PhoneInputMask from "./phoneInputMask";

const FormInput = ({ data, setSelectedType }) => {
  const { values, setFieldValue } = useFormikContext();
  console.log("first values", values);
  const {
    heading,
    label,
    fieldName,
    fieldType,
    options,
    placeholder,
    fieldValue,
    disable,
    country,
    phoneValue,
    defaultData,
    mask,
    maskChar,
    editValue,
  } = data;

  const [input, setInput] = useState();

  useEffect(() => {
    setInput(editValue);
  }, [editValue])

  console.log("defaultData", defaultData);

  return (
    <>
      {heading && (
        <Typography
          variant="h6"
          sx={{
            marginBottom: 2,
          }}
        >
          {heading}
        </Typography>
      )}
      {label && (
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: 1,
          }}
        >
          {label}
        </Typography>
      )}
      {fieldType === TextField ? (
        <Field
          as={fieldType}
          variant="outlined"
          fullWidth
          key={label}
          placeholder={`${placeholder ? placeholder : label ? label : fieldName
            }`}
          name={fieldName}
          disabled={disable}
        >
          <MenuItem value="">
            <>Select</>
          </MenuItem>
          {options?.map((item, index) => {
            return (
              <MenuItem key={index} value={item.name} label={item.label}>
                {item.label}
              </MenuItem>
            );
          })}
        </Field>
      ) : fieldType === Select ? (
        <>
          {fieldName === "speciality" ||
            fieldName === "required_expertise" ||
            fieldName === "condition" ||
            fieldName === "day_of_week" ? (
            <Autocomplete
              multiple
              limitTags={2}
              disableCloseOnSelect={true}
              name={fieldName}
              options={options.filter(
                (option) => !values[fieldName]?.includes(option?.name)
              )}
              value={values[fieldName] || []}
              variant="outlined"
              fullWidth
              onChange={(event, newValue) => {
                const newValueAsStringArray = newValue?.map((item) => {
                  if (typeof item === "string") {
                    return item;
                  } else if (item.name) {
                    return item.name;
                  }
                  return "";
                });
                console.log("newValueAsStringArray", newValueAsStringArray);
                setFieldValue(fieldName, newValueAsStringArray);
              }}
              key={fieldName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    !values?.[fieldName]?.length ||
                      values?.[fieldName]?.length >= 1
                      ? placeholder
                      : `Please select ${fieldName === "condition" ? "condition" : fieldName === "speciality" ? "speciality" : "day"
                      }`
                  }
                />
              )}
            />
          ) : (
            <Field name={fieldName} variant="outlined" fullWidth>
              {({ field }) => (
                <Autocomplete
                  {...field}
                  // disablePortal
                  id="combo-box-demo"
                  options={options}
                  disabled={disable}
                  getOptionLabel={(option) => option?.label || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      placeholder={`${placeholder ? placeholder : label ? label : fieldName
                        }`}
                      onChange={(e, newValue) => { }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    setFieldValue(fieldName, newValue ? newValue : "");
                  }}
                  value={field.value}
                />
              )}
            </Field>
          )}
        </>
      ) : fieldType === Radio ? (
        <Grid sx={{ marginBottom: "14px" }}>
          <FormControl component="fieldset">
            <Field name={fieldName}>
              {({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {fieldValue.map((choice, index) => (
                    <FormControlLabel
                      value={choice}
                      control={<Radio />}
                      label={choice}
                      style={{ display: "flex" }}
                      disabled={disable}
                    />
                  ))}
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        </Grid>
      ) : fieldType === "description" ? (
        <Field
          as={TextField}
          fullWidth
          minRows={3}
          maxRows={3}
          multiline={true}
          disabled={disable}
          name={fieldName}
          placeholder={`${placeholder ? placeholder : label ? label : fieldName
            }`}
          sx={{
            width: "100%",
          }}
        />
      ) : fieldType === "inputMask" ? (
        <InputMask
          mask={mask}
          maskChar={maskChar}
          alwaysShowMask
          value={input}
          disabled={disable}
          onChange={(e) => { console.log("12345", e.target.value); setFieldValue(fieldName, e.target.value); setInput(e.target.value) }}
        >
          {(inputProps) => (
            <TextField {...inputProps} variant="outlined" fullWidth disabled={disable} />
          )}
        </InputMask>
      ) : fieldType === "radioTextField" ? (
        <>
          <FormControl component="fieldset">
            <Field name={fieldName}>
              {({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    {fieldValue.map((choice, index) => (
                      <Grid item xs={12} xl={6} >
                        <TextField
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormControlLabel
                                  value={choice}
                                  control={<Radio />}
                                  label={choice}
                                  style={{ display: "flex" }}
                                  disabled={disable}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        </>
      ) : fieldType === "phoneNumber"? (
      <PhoneInputMask data={data}/>
    ) 
      :(
        <FormControl component="fieldset">
          <FormControl component="fieldset">
            <Field name={fieldName}>
              {({ field }) => (
                <FormGroup style={{ display: "flex", flexDirection: "row" }}>
                  {fieldValue.map((choice, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          {...field}
                          value={choice.value}
                          checked={field.value?.includes(choice.value)} // For managing multiple selections
                          disabled={disable}
                        />
                      }
                      label={choice.label}
                      style={{ marginRight: "10px" }} // Adjust spacing between checkboxes
                    />
                  ))}
                </FormGroup>
              )}
            </Field>
          </FormControl>
        </FormControl>
      )}
    </>
  );
};

export default FormInput;
