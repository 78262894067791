import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../store/slices/snackbarSlice";

export const useNotification = () => {
  const dispatch = useDispatch();

  const notifyError = useCallback((message) => {
    dispatch(openSnackbar({
      open: true,
      message,
      variant: 'alert',
      alert: { color: 'error' }
    }));
  }, []);

  const notifySuccess = useCallback((message) => {
    dispatch(openSnackbar({
      open: true,
      message,
      variant: 'alert',
      alert: { color: 'success' }
    }))
  }, []);

  const notify = useCallback((message) => {
    dispatch(openSnackbar({
      open: true,
      message,
      variant: 'alert',
      alert: { color: 'info' }
    }))
  }, []);

  return {
    notifyError, notifySuccess, notify
  }
}