import React, { lazy } from "react";

export const Signin = lazy(() => import("../views/authentication/sign-in"));

export const AuthRoutes = [
  {
    id: "Sign In",
    title: "Sign In",
    type: "item",
    icon: '',
    path: `/`,
    breadcrumbs: false,
    element: <Signin />,
  },
  {
    id: "Sign In",
    title: "Sign In",
    type: "item",
    icon: '',
    path: `*`,
    breadcrumbs: false,
    element: <Signin />,
  },
  {
    id: "Sign In",
    title: "Sign In",
    type: "item",
    icon: '',
    path: `/login`,
    breadcrumbs: false,
    element: <Signin />,
  },
]
