import config from "../../config/config";
import httpClient from "../../service/base";

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

export const UserApis = {

  getAllUsersList: (query) =>
    httpClient.get(`${BACKEND_BASE_URL}/customers/api/v1/staff_user`, { ...query }),

  getUserById: (id) =>
    httpClient.get(`${BACKEND_BASE_URL}/customers/api/v1/staff_user/${id}`,),

  addUser: (payload) =>
    httpClient.post(`${BACKEND_BASE_URL}/customers/api/v1/staff_user`, payload),

  updateUser: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}/customers/api/v1/staff_user/${id}`, payload),

  deleteUser: (id) =>
    httpClient.delete(`${BACKEND_BASE_URL}/customers/api/v1/staff_user/${id}`),

};


