import { useEffect, useState } from "react";
import {
  TextField,
  Typography
} from "@mui/material";
import {useFormikContext } from "formik";
import React from "react";
import InputMask from "react-input-mask";

const PhoneInputMask = ({ data, setSelectedType }) => {
    const { values, setFieldValue } = useFormikContext();
    console.log("first values", values);
    const {
      heading,
      label,
      fieldName,
      defaultData,
      maskChar,
      editValue,
    } = data;
  
    const [input, setInput] = useState(editValue || '');
  
    useEffect(() => {
      setInput(editValue || '');
    }, [editValue]);
  
    console.log("defaultData", defaultData);
  
    const handleChange = (e) => {
      e.target.value = e.target.value.replace(/\s+/g, '')
      setInput(e.target.value);
      setFieldValue(fieldName, e.target.value);
    };
  
    const phoneMask = (value) => {
      const digits = value.replace(/\D/g, '');
      if (digits.length > 10) {
        return "(99) 99999-9999";
      }
        return "(99) 9999-99999";
    };
  
    return (
      <>
        {(
          <InputMask
            mask={phoneMask(input)}
            maskChar={maskChar}
            value={input}
            onChange={handleChange}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                name={fieldName}
                variant="outlined"
                fullWidth
              />
            )}
          </InputMask>
        )}
      </>
    );
  };

export default PhoneInputMask;