import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import login_image from "../../../src/assets/BANNER_YT.jpg"

export const MainContainer = styled(Grid)({
  height: "100vh",
  display: "flex",
});

export const LeftSection = styled(Grid)({
  width: "85%",
  height: "100%",
  background: `url(${login_image})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  backgroundPosition: "center 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const RightSection = styled(Grid)({
  width: "15%",
  padding: "3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
