import config from "../../config/config";
import httpClient from "../../service/base";

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

const getUserId = () => {
  const details = JSON.parse(localStorage.getItem("user"));
  if (details?.user?.user?.user_type === "client_admin") {
    return details?.user?.uuid;
  } else {
    return details?.user?.tenant?.uuid;
  }
};

export const ProfileApi = {
  getProfileList: (query) => {
    const userId = getUserId();
    return httpClient.get(`${BACKEND_BASE_URL}/tenant/api/v1/profile/${userId}`, { ...query });
  },

  getProfile: (name) => {
    const userId = getUserId();
    return httpClient.get(`${BACKEND_BASE_URL}/tenant/api/v1/profile/${userId}/${name}`);
  },

  addprofile: (payload) => {
    const userId = getUserId();
    return httpClient.post(`${BACKEND_BASE_URL}/tenant/api/v1/profile/${userId}`, payload);
  },

  setPermission: (name, payload) => {
    const userId = getUserId();
    return httpClient.put(`${BACKEND_BASE_URL}/tenant/api/v1/profile/${userId}/${name}`, payload);
  }
};
