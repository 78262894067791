import { createContext, useEffect, useReducer, useCallback, useState } from 'react';
import httpClient from '../../service/base'
import { AuthApis } from "../../service/auth/auth"
import { loadState } from '../../helpers/storage';
import authReducer from "./auth-reducer"
import AuthActions from './auth-action';
import { useNotification } from '../../hooks/use-notification';
import { useTranslation } from "react-i18next";

const JWTContext = createContext(null);

const initState = loadState('user') ?? {
  tokens: null,
  user: null
};

export const JWTProvider = ({ children }) => {
  const { notifySuccess, notifyError } = useNotification();
  const [state, dispatch] = useReducer(authReducer, initState);
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const { tokens } = state;

  useEffect(() => {
    httpClient.init(AuthApis, dispatch);
    httpClient.accessToken = tokens?.accessToken;
    httpClient.refreshToken = tokens?.refreshToken;
    setInitialized(true);
  }, []);

  const login = useCallback(async ({ email, password }) => {
    try {
      const data = await AuthApis.signin(email, password);
      console.log("Inside login context", data)
      dispatch({
        type: AuthActions.setUser,
        payload: {
          user: data.user_data,
          tokens: data.token,
          permissions: data.permissions
        }
      })
      httpClient.accessToken = data?.token?.access;
      httpClient.refreshToken = data?.token?.refresh;
      notifySuccess(t("login_page.login_success"));
      if (data?.user_data?.user?.user_type === "super_admin") {
        window.location.href = "/customer-registration";
      } else {
        window.location.href = "/home";
      }
    }
    catch (error) {
      notifyError(t("login_page.login_error"));
      console.log("in error", error)
    }
  }, []);

  const isLoggedIn = useCallback(() => {
    console.log('user clicked on isloggedin')
    if (initState.tokens) {
      return true
    }
    else {
      return false
    }
  }, [])

  const logout = useCallback(async () => {
    try {
      const data = await AuthApis.signout();
      dispatch({
        type: AuthActions.clearUser
      })
      localStorage.removeItem('selectedLanguage');
      window.location.href = "/"
    }
    catch (error) {
      notifyError("Failed to Logout");
      console.log("in error", error)
    }
  }, []);


  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        isLoggedIn
      }}
    >
      {initialized && children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
