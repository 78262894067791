import AuthActions from './auth-action';
import { saveState } from '../../helpers/storage';

const initialState = {
  tokens: "",
  user: "",
  permissions: ""
}

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case AuthActions.setUser:
      console.log("action , state user", action, state)
      newState = {
        ...state,
        ...action.payload,
        tokens: action.payload.tokens,
        user: action.payload.user,
        permissions: action.payload.permissions
      };
      break;
    case AuthActions.clearUser:
      newState = {
        user: null,
        tokens: null,
        permissions: null
      };
      break;
    default:
      return state;
  }

  saveState('user', newState);
  return newState;
}

export default reducer;
