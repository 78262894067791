export const drawerWidth = "295px";

export const customDataTableStyles = {
  cells: {
    style: {
      textAlign: "left",
      fontStyle: "normal",
      fontFamily: "PT Sans",
      fontVariant: "medium",
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0.05em",
      color: "#6C757D",
      fontWeight: '400',
      opacity: 1,
    },
  },
  headRow: {
    style: {
      fontSize: "14px",
      fontFamily: "PT Sans",
      color: "#4F5052",
      fontWeight: "700",
      padding: '8px 16px 8px 0px',
      borderRadius: '8px',
      minHeight: "44px",
      lineHeight: "22px",
      letterSpacing: "0.05em"
    },
  },
};

export const customNotificationTableStyle = {
  rows: {
    style: {
      borderBottom: "none",
    },
  },
  cells: {
    style: {
      textAlign: "left",
      font: "normal normal 600 18px/26px Gilroy",
      letterSpacing: "0px",
      color: "#4C4C4CB2",
      opacity: 1,
    },
  },
};

export const cardStyle = {
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: "0px 0px 8px #00000029",
  borderRadius: "5px",
  opacity: 1,
};

export const token = JSON.parse(localStorage.getItem("user"))?.tokens?.accessToken
console.log("token", token);
export const base_url = "http://192.168.0.224:8000"

export const formatDate = (utcDateString, includeTime = false) => {
  const lng = localStorage.getItem("selectedLanguage");

  if (utcDateString !== null) {
    const utcDate = new Date(utcDateString);

    const day = utcDate.getDate().toString().padStart(2, '0');
    const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = utcDate.getFullYear();

    let formattedDate;

    if (lng === "en") {
      formattedDate = `${month}-${day}-${year}`;
    } else {
      formattedDate = `${day}-${month}-${year}`;
    }

    if (includeTime) {
      const hours = utcDate.getHours().toString().padStart(2, '0');
      const minutes = utcDate.getMinutes().toString().padStart(2, '0');
      const seconds = utcDate.getSeconds().toString().padStart(2, '0');
      formattedDate += ` ${hours}:${minutes}:${seconds}`;
    }

    return formattedDate;
  } else {
    return "-";
  }
};


export function formatCnpj(num) {
  const cnpj = String(num).replace(/[^\d]/g, ''); // Remove non-numeric characters
  if (cnpj.length === 11) {
    return `${cnpj.slice(0, 3)}.${cnpj.slice(3, 6)}.${cnpj.slice(6, 9)}-${cnpj.slice(9)}`;
  } else if (cnpj.length === 14) {
    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`;
  } else {
    // Handle invalid CNPJ length
    return "Invalid CNPJ";
  }
}

export const getInitials = (fullName) => {
  return fullName
    ?.split(' ')
    ?.map(namePart => namePart?.charAt(0))
    ?.slice(0, 2)
    ?.join('')?.toUpperCase();
};

export function calculateEndTime(startTimeStr, durationHours) {
  const startTime = new Date(startTimeStr);
  const endTime = new Date(startTime.getTime() + durationHours * 60 * 60 * 1000);
  return endTime.toISOString();
}
export const formatZipCode = (zipCode) => {
  if (!zipCode) return '';
  return zipCode.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export const groupTableStyle = {
  cells: {
    style: {
      textAlign: "left",
      fontStyle: "normal",
      fontVariant: "medium",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#4C4C4CB2",
      font: "normal normal medium 18px/21px Gilroy",
      opacity: 1,
    },
  },
  headRow: {
    style: {
      fontSize: "18px",
      backgroundColor: "#F8F8F8", // Change the background color of the header
      color: "#4C4C4C", // Change the text color of the header
      border: "0.8px solid #E9E9E9",
      font: "normal normal medium 18px/21px Gilroy",
      fontWeight: "500",
    },
  },
  rows: {
    style: {
      borderBottom: "none",
    },
  },
};

