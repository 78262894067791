const config = {

    // BACKEND_BASE_URL: `http://192.168.0.49:8000`,  //BE Omkar More Laptop
    // BACKEND_BASE_URL: `http://192.168.40.193:8000`,  //BE Omkar More Mobile
    // BACKEND_BASE_URL: `http://192.168.0.171:8000`,  //BE OmkarPrakash
    // BACKEND_BASE_URL: `http://192.168.0.254:8000`,  //BE Omkar Shevale Laptop
    BACKEND_BASE_URL: `https://api.fkn.thinkitive.cloud`,  //BE OmkarPrakash
    // BACKEND_BASE_URL: `http://192.168.15.234:8000`,  //BE Khushbu



};

export default config;