import httpClient from "../../service/base";
import Config from "../../config/config";

const BACKEND_BASE_URL = Config.BACKEND_BASE_URL;

export const AuthApis = {
  signin: (username, password) => httpClient.post(`${BACKEND_BASE_URL}/users/api/v1/login`, { username, password }, { authentication: false }),
  signout: () => httpClient.post(`${BACKEND_BASE_URL}/users/api/v1/logout`),
  validateSubdomain: () => httpClient.get(`${BACKEND_BASE_URL}/users/api/v1/verify_domain`),
};
