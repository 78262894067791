import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ConfirmBox({ open, handleSubmit, handleClose, action }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} sx={{ padding: "5px" }}>
      <DialogTitle>
        <Box textAlign={"center"}>
          <WarningAmberOutlinedIcon
            color="warning"
            fontSize="60px !important"
            sx={{ height: "80px", width: "80px", color: "#D92D20" }}
          />
        </Box>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ color: "#727272" }}>
            {t("confirm_box.confirm")} {action} ?
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <DialogContentText
          variant="subtitle1"
          textAlign="center"
          sx={{ color: "#727272" }}
        >
          {t("confirm_box.message")} {action} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "15px" }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleClose}
            >
              {t("confirm_box.cancel")}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              {t("confirm_box.yes")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
