import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { toggleDrawer } from "../../store/slices/drawerSlices";
import { drawerWidth } from "../../constant";
import { menus } from "./menus";
import { CustomScrollbars } from "../../ui-component/ScrollBar";
import AuthContext from "../../contexts/auth/auth-context";
import ConfirmBox from "../../ui-component/ConfirmBox";
import { useTranslation } from "react-i18next";

export const SideNav = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext)
  const [selectedItem, setSelectedItem] = useState("Analytics");
  const [openCnf, setOpenCnf] = useState(false);
  const [action, setAction] = useState(null);
  const [openGroups, setOpenGroups] = useState({});
  const navigate = useNavigate();
  const store = useSelector((state) => state);
  const [menusItems, setMenuItems] = useState([]);

  useEffect(() => {
    const storedPermissions = localStorage.getItem('user');
    const userDetails = JSON.parse(storedPermissions)?.user;

    let parsedPermissions = null
    if (storedPermissions) {
      parsedPermissions = JSON.parse(storedPermissions)?.permissions;
    }

    let filteredRoutes = menus?.children?.filter(menuItem => {
      return parsedPermissions?.find(permission => (permission.codename === menuItem.id) && (permission.is_set)) || menuItem.id === "logout";
    }) || [];

    if (userDetails?.client === null) {
      if (userDetails?.client_type === "admin") {
        setMenuItems(menus?.children);
      }
    }
    else {
      setMenuItems(filteredRoutes);
    }
    console.log("Filtered Routes:", filteredRoutes);
  }, [menus]);

  const location = useLocation();

  const routesWithoutHeader = [
    "/signin",
    "/forgotPassword",
    "/resetPassword",
    "/otp",
    "/logout",
    "/",
  ];

  // Create a state object to track the open state of each group
  const toggleGroup = (groupId) => {
    // Toggle the open state of the clicked group
    setOpenGroups((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }));
  };

  const handleItemClick = (item) => {
    setSelectedItem(item.title);
    navigate(`${item.url}`);
  };

  const handleLogout = (item) => {
    logout();
  };

  // Use the useEffect hook to update the selectedItem based on location.pathname
  useEffect(() => {
    let menuItem = menus.children.find(
      (item) => item.url === location.pathname
    );

    if (!menuItem) {
      menus.children.forEach((item) => {
        if (item.children) {
          const subMenuItem = item.children.find(
            (subItem) => subItem.url === location.pathname
          );
          if (subMenuItem) {
            menuItem = item; // Set the parent item as the selected item
          }
        }
      });
    }

    if (menuItem) {
      setSelectedItem(menuItem.title);
    }

  }, [location.pathname]);

  // set children menu position
  const renderMenuItems = (items, depth = 0) => {
    const marginLeft = depth * 20;
    if (routesWithoutHeader.includes(location.pathname)) {
      return null;
    }

    return (
      <List>
        {items.map((item) => (
          <div key={item.id}>
            {item.type === "item" ? (
              <ListItem
                button
                onClick={() => handleItemClick(item)}
                selected={item.title === selectedItem}
                sx={{
                  marginLeft: `${marginLeft}px`,
                  borderRadius: "8px",
                  "& .MuiTypography-body1": {
                    fontSize: "14px",
                    fontFamily: "PT Sans",
                    fontWeight: 400,
                    lineHeight: "18.23px",
                    letterSpacing: "0.6px",
                    color: "#4F5052"
                  },
                  "& .MuiListItemIcon-root": {
                    color: "#4F5052",
                  },
                  "&:hover": {
                    backgroundColor: "#398F5A",
                    "& .MuiTypography-body1": { // Apply styles to typography inside hover state
                      color: "#FFFFFF",
                      fontWeight: 500,
                      fontSize: "14px"
                    },
                    // border: '1px solid #42427C',
                  },
                  marginBottom: "15px",
                  "&.Mui-selected": {
                    backgroundColor: "#398F5A !important",
                    // border: '1px solid #42427C',
                  },
                  "& .MuiTypography-body1 ": item.title === selectedItem && {
                    color: "#FFFFFF",
                    fontWeight: 500,
                    fontSize: "14px"
                  },
                  "& .MuiListItemIcon-root": item.title === selectedItem && {
                    color: "#FFFFFF",
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <Avatar variant="rounded" sx={{ width: 30, height: 30, backgroundColor: "#ffffff", color: "#4F5052", boxShadow: "0px 0px 4px #00000020", }}>
                    {item.icon}
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={t(`sidenav.${item.id}`)} />
              </ListItem>
            ) : item.type === "logout" ? (
              <ListItem
                button
                onClick={() => {
                  setOpenCnf(true)
                  setAction(t("confirm_box.logout"))
                }}
                selected={item.title === selectedItem}
                sx={{
                  marginLeft: `${marginLeft}px`,
                  borderRadius: "8px",
                  "& .MuiTypography-body1": {
                    fontSize: "14px",
                    fontFamily: "PT Sans",
                    fontWeight: 400,
                    lineHeight: "18.23px",
                    letterSpacing: "0.6px",
                    color: "#4F5052"
                  },
                  "& .MuiListItemIcon-root": {
                    color: "#4F5052",
                  },
                  "&:hover": {
                    backgroundColor: "#398F5A",
                    "& .MuiTypography-body1": { // Apply styles to typography inside hover state
                      color: "#FFFFFF",
                      fontWeight: 500,
                    },
                    // border: '1px solid #42427C',
                  },
                  marginBottom: "15px",
                  "&.Mui-selected": {
                    backgroundColor: "#398F5A !important",
                    // border: '1px solid #42427C',
                  },
                  "& .MuiTypography-body1 ": item.title === selectedItem && {
                    color: "#FFFFFF",
                    fontWeight: 500,
                  },
                  "& .MuiListItemIcon-root": item.title === selectedItem && {
                    color: "#FFFFFF",
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <Avatar variant="rounded" sx={{ width: 30, height: 30, backgroundColor: "#ffffff", color: "#4F5052", boxShadow: "0px 0px 4px #00000020", }}>
                    {item.icon}
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={t(`sidenav.${item.id}`)} />
              </ListItem>
            ) : item.type === "group" ? (
              <div>
                <ListItem
                  button
                  onClick={() => toggleGroup(item.id)}
                  sx={{
                    marginLeft: `${marginLeft}px`,
                    borderRadius: "8px",
                    "& .MuiTypography-body1": {
                      fontSize: "14px",
                      fontFamily: "PT Sans",
                      fontWeight: 400,
                      lineHeight: "18.23px",
                      letterSpacing: "0.6px",
                      color: "#4F5052"
                    },
                    "& .MuiListItemIcon-root": {
                      color: "#4F5052",
                    },
                    "&:hover": {
                      backgroundColor: "#D3D3FF",
                      "& .MuiTypography-body1": { // Apply styles to typography inside hover state
                        color: "#FFFFFF",
                        fontWeight: 500,
                      },
                      // border: '1px solid #42427C',
                    },
                    marginBottom: "15px",
                    "&.Mui-selected": {
                      backgroundColor: "#D3D3FF !important",
                      // border: '1px solid #42427C',
                    },
                    "& .MuiTypography-body1 ": item.title === selectedItem && {
                      color: "#42427C",
                      fontWeight: 500,
                    },
                    "& .MuiListItemIcon-root": {
                      color: "#42427C",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={t(`sidenav.${item.id}`)} />
                  <ListItemIcon>
                    {openGroups[item.id] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </ListItemIcon>
                </ListItem>
                <Collapse in={openGroups[item.id]}>
                  {renderMenuItems(item.children, depth + 1)}
                </Collapse>
              </div>
            ) : null}
          </div>
        ))}
      </List>
    );
  };

  if (routesWithoutHeader.includes(location.pathname)) {
    return null;
  }

  return (
    <Grid style={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginTop: "64px",
            display: "flex", // Use flex display
            flexDirection: "column", // Column layout for the drawer content
            alignItems: "center", // Center the content vertically
            justifyContent: "space-between", // Space between content
          },

        }}
        variant="persistent"
        anchor="left"
        open={store.drawer.open}
        onClose={toggleDrawer}
      >
        <CustomScrollbars height={"95vh"}>
          <Box sx={{ width: "80%", marginLeft: "25px", marginTop: "10px" }}>
            {renderMenuItems(menusItems)}
          </Box>
        </CustomScrollbars>
      </Drawer>
      <ConfirmBox
        handleClose={() => setOpenCnf(false)}
        open={openCnf}
        handleSubmit={() => handleLogout()}
        action={action}
      />
    </Grid>
  )
};
