
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  staffUser: false,
  technician: false,
  equipment: false,
  allServices: [],
};

const updateData = createSlice({
  name: 'updateData',
  initialState,
  reducers: {
    updateStaffUser: (state, action) => {
      state.staffUser = action.payload
    },
    updateTechnicianData: (state, action) => {
      state.technician = action.payload
    },
    updateEquipmentData: (state, action) => {
      state.equipment = action.payload
    },
    updateServices: (state, action) => {
      state.allServices = action.payload
    },
    addService: (state, action) => {
      state.allServices.push(action.payload);
    },
    removeService: (state, action) => {
      state.allServices = state.allServices.filter(service => service.uuid !== action.payload);
    },
    clearServices: (state) => {
      state.allServices = [];
    },
  },
});

export const { updateStaffUser, updateTechnicianData, updateEquipmentData, updateServices, addService, removeService, clearServices } = updateData.actions;

export default updateData.reducer;
