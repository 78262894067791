import { configureStore } from '@reduxjs/toolkit';
import snackbarSlice from './slices/snackbarSlice';
import drawerSlices from './slices/drawerSlices';
import navTabSlices from './slices/NavTabsSlices';
import updateDataSlice from './slices/updateDataSlice';


export const store = configureStore({
  reducer: {
    snackbar: snackbarSlice,
    drawer: drawerSlices,
    navTabIndex: navTabSlices,
    updateAllData: updateDataSlice
  },
});

