import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  CircularProgress,
  Select,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import cardStyle from "../../../theme/card-layout";
import { useNotification } from "../../../hooks/use-notification";
import { updateStaffUser } from "../../../store/slices/updateDataSlice";
import { UserApis } from "../../../service/Users/users";
import { ProfileApi } from "../../../service/Profile/profileApi";
import FormInput from "../../../ui-component/reusableInputs";

const AddUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { edit, uuid } = location.state || {};
  const { notifySuccess, notifyError } = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [userData, setUserData] = useState();
  const [profileOption, setProfileOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gettingData, setGettingData] = useState(false);

  const getUserId = () => {
    const details = JSON.parse(localStorage.getItem("user"));
    if (details?.user?.user?.user_type === "client_admin") {
      return details?.user?.uuid;
    } else {
      return details?.user?.tenant?.uuid;
    }
  };

  const userId = getUserId();

  const fields = [
    {
      label: "Name",
      fieldName: "name",
      fieldType: TextField,
    },
    {
      label: "Role",
      fieldName: "role",
      fieldType: TextField,
    },
    {
      label: "User Name",
      fieldName: "userName",
      fieldType: TextField,
    },
    {
      label: "E-mail",
      fieldName: "email",
      fieldType: TextField,
    },
    {
      label: "Profile",
      fieldName: "profile",
      fieldType: Select,
      options: profileOption
    },
  ]

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("user_management.add_user.validations.validmail"))
      .matches(/^.*@.+\..+$/, t("user_management.add_user.validations.validmail"))
      .required(t("user_management.add_user.validations.mail")),
    name: Yup.string().required(t("user_management.add_user.validations.name")),
    role: Yup.string().required(t("user_management.add_user.validations.role")),
    profile: Yup.object().required(t("user_management.add_user.validations.profile")),
    userName: Yup.string().required(t("user_management.add_user.validations.user_name")),
    password: Yup.string()
      .required(t("user_management.add_user.validations.password"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
        t("user_management.add_user.validations.validpass")
      ),
    confirm_password: Yup.string()
      .required(t("user_management.add_user.validations.cnfpassword"))
      .oneOf([Yup.ref('password'), null], t("user_management.add_user.validations.matchpass")),
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleCnfPasswordVisibility = () => {
    setShowCnfPassword(!showCnfPassword);
  };

  const addData = (payload) => {
    setLoading(true);
    UserApis.addUser(payload)
      .then((response) => {
        console.log("Add User", response);
        notifySuccess(t("user_management.success.addUser"));
        setLoading(false);
        dispatch(updateStaffUser(true));
        navigate("/users");
      })
      .catch((error) => {
        console.error("Error fetching add user data:", error);
        notifyError(error?.response?.data?.error);
        setLoading(false);
      });
  }

  const editData = (payload) => {
    setLoading(true);
    UserApis.updateUser(uuid, payload)
      .then((response) => {
        console.log("Edit User", response);
        notifySuccess(t("user_management.success.updateUser"));
        setLoading(false);
        dispatch(updateStaffUser(true));
        navigate("/users");
      })
      .catch((error) => {
        console.error("Error fetching edit user data:", error);
        notifyError(error?.response?.data?.error);
        setLoading(false);
      });
  }

  const getUserDetails = () => {
    setGettingData(true);
    UserApis.getUserById(uuid).then((response) => {
      console.log("response", response?.data)
      setUserData(response?.data);
      setGettingData(false);
    }).catch((error) => {
      console.log("error while fetching user details", error);
      setGettingData(false);
    })
  }

  useEffect(() => {
    if (edit) { getUserDetails() };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    const query = {
      list: true
    }
    ProfileApi.getProfileList(query).then((response) => {
      console.log("profile list response", response?.data);
      const transformedData = response?.data.map((item) => ({
        label: item?.display_name,
        name: item?.name,
      }));
      setProfileOption(transformedData);
    }).catch((error) => {
      console.log("error while fetching profile list details", error);
    })
  }, [])


  return (
    <Grid p={4}>
      <Grid item xs={12} mb={2}>
        <Typography variant="table_header">{edit ? t("user_management.add_user.update") : t("user_management.add_user.add")}</Typography>
      </Grid>
      {!gettingData ?
        <Grid item xs={12}>
          <Card sx={{ ...cardStyle }}>
            <CardContent>
              <Formik
                initialValues={{
                  name: edit ? userData?.name : "",
                  email: edit ? userData?.user?.email : "",
                  role: edit ? userData?.role : "",
                  profile: edit ? { label: userData?.profile, name: `${userId}_${userData?.profile}` } || "" : "",
                  password: edit ? userData?.user?.password : "",
                  confirm_password: edit ? userData?.user?.password : "",
                  userName: edit ? userData?.user?.username : "",
                }}
                validationSchema={edit ? null : validationSchema}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  const body = {
                    name: values.name,
                    role: values.role,
                    profile: values.profile.name,
                  };
                  if (!edit) {
                    body['user'] = {
                      email: values.email,
                      password: values.password,
                      username: values.userName,
                    }
                  }
                  if (edit) {
                    editData(body);
                  }
                  else {
                    addData(body);
                  }
                  setSubmitting(false);
                }}
              >
                {({ errors, touched }) => (
                  <Grid>
                    <Form>
                      <Grid container spacing={4}>
                        {fields?.slice(0, 2)?.map((field) => (
                          <Grid item xs={6}>
                            <React.Fragment key={field.key}>
                              <>
                                <FormInput data={field} />
                                <ErrorMessage
                                  name={field.fieldName}
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "15px",
                                    marginBottom: 1,
                                    marginTop: "10px",
                                  }}
                                />
                              </>
                            </React.Fragment>
                          </Grid>
                        ))}
                        {!edit &&
                          <>
                            {fields?.slice(2, 4)?.map((field) => (
                              <Grid item xs={6}>
                                <React.Fragment key={field.key}>
                                  <>
                                    <FormInput data={field} />
                                    <ErrorMessage
                                      name={field.fieldName}
                                      component="div"
                                      style={{
                                        color: "red",
                                        fontSize: "15px",
                                        marginBottom: 1,
                                        marginTop: "10px",
                                      }}
                                    />
                                  </>
                                </React.Fragment>
                              </Grid>
                            ))}
                            <Grid item xs={6}>
                              <Typography mb={1}>{t("user_management.add_user.password")}</Typography>
                              <Field
                                as={TextField}
                                fullWidth
                                name="password"
                                id="password"
                                type={showPassword ? "text" : "password"}
                                variant="outlined"
                                error={
                                  touched.password && errors.password ? true : false
                                }
                                placeholder={t("user_management.add_user.placeholders.password")}
                                helperText={touched.password && errors.password}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        color="primary"
                                        onClick={handleTogglePasswordVisibility}
                                        disabled={edit}
                                      >
                                        {!showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={edit}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography mb={1}>{t("user_management.add_user.cnfpassword")}</Typography>
                              <Field
                                as={TextField}
                                fullWidth
                                name={edit ? "password" : "confirm_password"}
                                id={edit ? "password" : "confirm_password"}
                                type={showCnfPassword ? "text" : "password"}
                                variant="outlined"
                                error={
                                  touched.confirm_password && errors.confirm_password ? true : false
                                }
                                placeholder={t("user_management.add_user.placeholders.cnfpassword")}
                                helperText={touched.confirm_password && errors.confirm_password}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        color="primary"
                                        onClick={handleToggleCnfPasswordVisibility}
                                        disabled={edit}
                                      >
                                        {!showCnfPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={edit}
                              />
                            </Grid>
                          </>
                        }
                        {fields?.slice(4, 5)?.map((field) => (
                          <Grid item xs={6}>
                            <React.Fragment key={field.key}>
                              <>
                                <FormInput data={field} />
                                <ErrorMessage
                                  name={field.fieldName}
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "15px",
                                    marginBottom: 1,
                                    marginTop: "10px",
                                  }}
                                />
                              </>
                            </React.Fragment>
                          </Grid>
                        ))}
                        <Grid item xs={6}></Grid>
                      </Grid>
                      <Grid item xs={6} mt={2} display="flex" justifyContent="end">
                        <Button
                          type="submit"
                          variant="outlined"
                          fullWidth
                          sx={{
                            marginRight: 2,
                            width: 200,
                          }}
                          onClick={() => {
                            navigate("/users");
                          }}
                        >
                          {t("actions.cancel")}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          sx={{
                            width: 200,
                          }}
                        >
                          {edit ? t("user_management.add_user.save") : t("user_management.add_user.create")}
                          {loading && (
                            <CircularProgress
                              size={18}
                              style={{ color: "white", marginLeft: "10%" }}
                            />
                          )}
                        </Button>
                      </Grid>
                    </Form>
                  </Grid>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid> :
        <>
          <CircularProgress
            color="primary"
            sx={{ marginTop: "36vh", marginLeft: "38vw" }}
          />
        </>
      }
    </Grid>
  );
};

export default AddUser;
