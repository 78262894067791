import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSquarePlus } from "react-icons/fa6";
import { GoPencil } from "react-icons/go";
import {
  Grid,
  Typography,
  Card,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
  Popover,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import GroupIcon from "@mui/icons-material/Group";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customDataTableStyles } from "../../../constant";
import cardStyle from "../../../theme/card-layout";
import ConfirmBox from "../../../ui-component/ConfirmBox";
import { useNotification } from "../../../hooks/use-notification";
import { updateStaffUser } from "../../../store/slices/updateDataSlice";
import { UserApis } from "../../../service/Users/users";
import CustomPagination from "../../../ui-component/Pagination";

const StatusBadge = ({ status }) => {
  const badgeStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
    backgroundColor: "#ECFDF3",
    color: "#398F5A",
    width: "100px",
    height: "35px",
    fontSize: "14px",
    border: "1.2px dotted #398F5A",
    padding: "1px",
  };

  return (
    <div style={badgeStyle}>
      <span
        style={{
          color: "#398F5A",
          fontSize: "20px",
          marginRight: "5px",
          marginLeft: "5px",
          marginTop: "4px",
        }}
      >
        <GroupIcon />
      </span>
      <span style={{ marginRight: "5px" }}>{status}</span>
    </div>
  );
};

const UsersList = () => {
  const { t } = useTranslation();
  const { updateAllData } = useSelector((state) => state);
  const { notifySuccess, notifyError } = useNotification()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [openCnf, setOpenCnf] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [action, setAction] = useState(null);
  const [sortDirection, setSortDirection] = useState("-created_at");

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleEdit = () => {
      console.log(`Editing row with ID ${row.uuid}`);
      navigate(`/users/add`, {
        state: {
          edit: true,
          uuid: row.uuid,
        },
      });
    };

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>
              <GoPencil style={{ color: "#0397EB", marginRight: 4 }} />
              <Typography color="#0397EB" fontSize={"16px"}>
                {t("actions.edit")}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setOpenCnf(true);
                setDeleteId(row.uuid);
                setAction(t("confirm_box.delete"));
              }}
            >
              <RiDeleteBinLine style={{ color: "#E53E3E", marginRight: 4 }} />
              <Typography color="#E53E3E" fontSize={"16px"}>
                {t("actions.delete")}
              </Typography>
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  const columns = [
    {
      name: t("user_management.data_table.name"),
      selector: (row) => row?.name,
      sortable: true,
      width: "25%",
    },
    {
      name: t("user_management.data_table.role"),
      selector: (row) => row?.role ? row?.role : "-",
      sortable: true,
      width: "20%",
    },
    {
      name: t("user_management.data_table.profile"),
      selector: (row) => row?.profile,
      sortable: true,
      width: "25%",
    },
    {
      name: t("user_management.data_table.email"),
      selector: (row) => row?.user?.email,
      sortable: true,
      width: "20%",
    },
    {
      name: t("actions.action"),
      cell: (row) => <MoreButton row={row} />,
      width: "10%",
    },
  ];

  const handleRowClicked = (row) => {
    getUserDetails(row?.uuid);
    setShowUserDetails(true);
  };

  const getUserDetails = (id) => {
    UserApis.getUserById(id).then((response) => {
      console.log("user details response", response?.data)
      setSelectedUser(response?.data)
    }).catch((error) => {
      console.log("error while fetching user details", error);
    })
  }

  const getAllUserDetails = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      order_by: sortDirection,
    }
    UserApis.getAllUsersList(query)
      .then((response) => {
        console.log("Users list", response);
        setData(response.data);
        setPageCount(response?.count)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Users data:", error);
        setLoading(false);
      });
  }

  const handleDeleteSubmit = async () => {
    dispatch(updateStaffUser(false));
    UserApis.deleteUser(deleteId)
      .then((response) => {
        console.log("Deleted User", response);
        setOpenCnf(false);
        notifySuccess(t("user_management.success.deleteUser"))
        dispatch(updateStaffUser(true));
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching delete users data:", error);
        notifyError(error?.response?.data?.error)
      });
  }

  useEffect(() => {
    getAllUserDetails();
  }, [updateAllData, currentPage, sortDirection]);

  const handleSort = (column, sortDirection) => {
    switch (column.name) {
      case "Name":
        setSortDirection(sortDirection === "desc" ? "-name" : "name");
        break;
      case "Role":
        setSortDirection(sortDirection === "desc" ? "-role" : "role");
        break;
      case "Profile":
        setSortDirection(
          sortDirection === "desc" ? "-user__groups__profile__display_name" : "user__groups__profile__display_name"
        );
        break;
      case "Email":
        setSortDirection(
          sortDirection === "desc" ? "-user__email" : "user__email"
        );
        break;
    }
  };

  return (
    <>
      <Grid container p={4}>
        {!showUserDetails ? (
          <>
            {loading ? (
              <CircularProgress
                color="primary"
                sx={{ marginTop: "36vh", marginLeft: "38vw" }}
              />
            ) : (<>
              <DataTable
                title={
                  <>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item justifyContent={"start"}>
                        <Typography variant="table_header">
                          {t("user_management.data_table.title")}
                        </Typography>
                      </Grid>

                      <Grid item justifyContent={"end"}>
                        <Button
                          style={{
                            marginRight: "8px",
                            textTransform: "none",
                            width: "150px",
                          }}
                          onClick={() => {
                            navigate("/users/profiles");
                          }}
                        >
                          <StatusBadge status={t("user_management.data_table.profile")} />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            marginRight: "8px",
                            textTransform: "none",
                            width: "150px",
                          }}
                          onClick={() => {
                            navigate("/users/add");
                          }}
                        >
                          <FaRegSquarePlus size={20} color="#FFFFFF" />&nbsp; {t("user_management.data_table.new_user")}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                }
                onRowClicked={handleRowClicked}
                onSort={handleSort}
                columns={columns}
                data={data}
                theme="solarized"
                customStyles={customDataTableStyles}
              />
              <Grid item xs={12} marginTop={2} marginRight={2} style={{ position: 'fixed', bottom: 0, width: "-webkit-fill-available", zIndex: 999 }} >
                {pageCount !== 0 &&
                  <CustomPagination
                    count={pageCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    data={data}
                  />
                }
              </Grid>
            </>)}
          </>
        ) : (
          <>
            <Grid container mb={1}>
              <ArrowBackIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
                onClick={() => {
                  setShowUserDetails(false);
                  setSelectedUser(null);
                }}
              />
              <Typography
                gutterBottom
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  fontFamily: "Exo 2",
                }}
              >
                {t("user_management.user_details.title")}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
              }}
              spacing={2}
            >
              <Grid item xs={12} xl={6} lg={6} md={12} sm={12}>
                <Card style={{ ...cardStyle }}>
                  <Grid container p={2}>
                    <Grid
                      item
                      xs={12}
                      pb={0}
                      style={{ borderBottom: "1px solid #B3DAC2" }}
                    >
                      <Typography
                        gutterBottom
                        style={{
                          fontSize: "18px",
                          fontWeight: 700,
                        }}
                      >
                        {t("user_management.user_details.data")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} pt={2}>
                      <Typography
                        style={{ color: "#818284", fontSize: "14px" }}
                        gutterBottom
                      >
                        {t("user_management.data_table.name")}
                      </Typography>
                      <Typography
                        style={{
                          color: "#272727",
                          fontSize: "14px",
                          paddingBottom: "5px",
                        }}
                        gutterBottom
                      >
                        {selectedUser?.name}
                      </Typography>
                      <Typography
                        style={{ color: "#818284", fontSize: "14px" }}
                        gutterBottom
                      >
                        {t("user_management.data_table.role")}
                      </Typography>
                      <Typography
                        style={{
                          color: "#272727",
                          fontSize: "14px",
                          paddingBottom: "5px",
                        }}
                        gutterBottom
                      >
                        {selectedUser?.role}
                      </Typography>
                      <Typography
                        style={{ color: "#818284", fontSize: "14px" }}
                        gutterBottom
                      >
                        {t("user_management.data_table.email")}
                      </Typography>
                      <Typography
                        style={{
                          color: "#272727",
                          fontSize: "14px",
                          paddingBottom: "5px",
                        }}
                        gutterBottom
                      >
                        {selectedUser?.user?.email}
                      </Typography>
                      <Typography
                        style={{ color: "#818284", fontSize: "14px" }}
                        gutterBottom
                      >
                        {t("user_management.data_table.profile")}
                      </Typography>
                      <Typography
                        style={{ color: "#272727", fontSize: "14px" }}
                        gutterBottom
                      >
                        {selectedUser?.profile}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} xl={6} lg={6} md={12} sm={12}>
                <Card style={{ ...cardStyle }}>
                  <Grid container p={2}>
                    <Grid
                      item
                      xs={12}
                      pb={0}
                      style={{ borderBottom: "1px solid #B3DAC2" }}
                    >
                      <Typography
                        gutterBottom
                        style={{
                          fontSize: "18px",
                          fontWeight: 700,
                        }}
                      >
                        {t("user_management.user_details.permissions")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} pt={2} style={{ maxHeight: '600px', overflowY: 'auto' }}>
                      {
                        selectedUser?.permissions?.map((permission) => (
                          <Grid>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={permission?.codename}
                                  color="primary"
                                  size="small"
                                  checked={true}
                                />
                              }
                              style={{ color: "#4F5052" }}
                              label={permission?.name}
                            />
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
        <ConfirmBox
          handleClose={() => setOpenCnf(false)}
          open={openCnf}
          handleSubmit={() => handleDeleteSubmit()}
          action={action}
        />
      </Grid>
    </>
  );
};
export default UsersList;
