import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { JWTProvider as AuthProvider } from "./contexts/auth";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import i18next from "i18next";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import Subdomain from "./views/subdomain/subdomain";

const theme = createTheme({
  palette: {
    primary: {
      main: "#398F5A",
    },
    secondary: {
      main: "#FFFFFF",
    },
    tertiary: {
      main: "#F2F3F3"
    },
    error: {
      main: "#FF0000",
    },
  },
  typography: {
    fontFamily:
      "PT Sans",
    h6: {
      fontSize: "2rem",
      fontWeight: "bold",
      font: "PT Sans",
      letterSpacing: "0px",
      color: "#4C4C4C",
    },
    table_header: {
      fontSize: "20px",
      fontWeight: 700,
      fontFamily: "PT Sans",
      letterSpacing: "0.7px",
      color: "#272727",
      lineHeight: "24px"
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <Subdomain>
              <App />
            </Subdomain>
          </I18nextProvider>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
