import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import {
  Grid,
  Typography,
  Card,
  Button,
  FormControlLabel,
  Checkbox,
  Popover,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  CircularProgress
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import cardStyle from "../../../theme/card-layout";
import { useNotification } from "../../../hooks/use-notification";
import { ProfileApi } from "../../../service/Profile/profileApi";

const Profiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotification();
  const [profileName, setProfileName] = useState("");
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [selectedItem, setSelectedItem] = useState();
  const [selectedProfile, setSelectedProfile] = useState();
  const [data, setData] = useState();
  const [profile, setProfile] = useState(null);
  const [perLoading, setPerLoading] = useState(false);

  const handleItemClick = (item) => {
    setSelectedItem(item?.display_name);
    setSelectedProfile(item);
    ProfileApi.getProfile(item?.name)
      .then((response) => {
        setProfile(response?.data?.permissions);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  };

  const getProfileList = () => {
    ProfileApi.getProfileList()
      .then((response) => {
        setData(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching profile list data:", error);
      });
  };

  useEffect(() => {
    getProfileList();
  }, []);

  const colors = ["#003E61", "#FF9900", "#398F5A", "#7700FF"];

  const [anchorEl, setAnchorEl] = useState(null);
  const [addLoading, setAddLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const addProfile = async () => {
    const payload = {
      display_name: profileName,
    };
    setAddLoading(true);
    ProfileApi.addprofile(payload)
      .then((response) => {
        console.log("Add profile", response);

        notifySuccess(t("user_management.success.addProfile"));
        setProfileName("");
        setAddLoading(false);
        getProfileList();
        handleClose();
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        setAddLoading(false);
      });
  };

  const createProfile = () => {
    addProfile();
  };

  const handleCheckboxChange = (permission) => (event) => {
    setCheckedPermissions({
      ...checkedPermissions,
      [permission]: event.target.checked,
    });
  };

  const allPermissionsChecked = Object.values(checkedPermissions).every(
    (isChecked) => isChecked
  );

  const handleSubmitPermission = () => {
    const truePermissions = Object.entries(checkedPermissions)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
    const payload = {
      permissions: truePermissions
    };
    setPerLoading(true);
    ProfileApi.setPermission(selectedProfile.name, payload)
      .then((response) => {
        console.log("Set permissions", response);
        notifySuccess(t("user_management.success.savePermissions"))
        setPerLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching permissions data:", error);
        setPerLoading(false);
      });
  };

  useEffect(() => {
    const permissionsObject = profile?.reduce((acc, permission) => {
      acc[permission.codename] = permission.is_set;
      return acc;
    }, {});
    setCheckedPermissions(permissionsObject || {});
  }, [profile, selectedItem]);

  return (
    <>
      <Grid p={4}>
        <>
          <Grid container mb={1}>
            <Grid
              container
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ marginRight: "20px", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/users");
                  }}
                />
                <Typography
                  variant="table_header"
                >
                  {t("user_management.data_table.profile")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "8px",
                    textTransform: "none",
                    width: "150px",
                  }}
                  onClick={handleClick}
                >
                  + {t("user_management.add_profile.addbutton")}
                  {addLoading && (
                    <CircularProgress
                      size={18}
                      style={{ color: "white" }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
            }}
            spacing={4}
            marginTop={1}
          >
            <Grid item xs={5}>
              <Card style={{ ...cardStyle }}>
                <Grid conatiner p={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    style={{ textTransform: "none", borderRadius: "30px" }}
                    onClick={() => { }}
                  >
                    {t("user_management.data_table.profile")}
                  </Button>
                </Grid>
                <Grid p={2} style={{ height: "47vh", overflowY: "auto" }}>
                  <List>
                    {data?.map((text, index) => (
                      <ListItem
                        key={text?.display_name}
                        disablePadding
                        button
                        onClick={() => handleItemClick(text)}
                        selected={text?.display_name === selectedItem}
                        sx={{
                          borderRadius: "8px",
                          marginBottom: "5px",
                          "&.Mui-selected": {
                            backgroundColor: "#D0DADF !important",
                          },
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <BsPerson
                              size="1.5rem"
                              style={{ color: colors[index % 4] }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={text?.display_name}
                            sx={{
                              "& .MuiTypography-body1 ": {
                                color: colors[index % 4],
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={7}>
              <Card style={{ ...cardStyle }}>
                <Grid container p={2}>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {t("user_management.add_profile.permissions")}
                    </Typography>
                  </Grid>
                  <Grid container style={{ height: "auto", overflowY: "auto" }}>
                    {profile ? <>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="allPermissions"
                              color="primary"
                              size="small"
                              checked={allPermissionsChecked}
                              onChange={(event) => {
                                const checked = event.target.checked;
                                const updatedPermissions = {};
                                profile?.forEach(
                                  (permission) =>
                                  (updatedPermissions[permission?.codename] =
                                    checked)
                                );
                                setCheckedPermissions(updatedPermissions);
                              }}
                            />
                          }
                          style={{ color: "#4F5052" }}
                          label="All"
                        />
                      </Grid>
                      {profile?.map((permission) => (
                        <Grid item xs={12} key={permission.codename}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={permission.name}
                                color="primary"
                                size="small"
                                checked={
                                  checkedPermissions[permission.codename] || false
                                }
                                onChange={handleCheckboxChange(
                                  permission.codename
                                )}
                              />
                            }
                            style={{ color: "#4F5052" }}
                            label={permission.name}
                          />
                        </Grid>
                      ))}
                    </> : <Grid container alignItems={"center"} justifyContent={"center"}><Typography>{t("user_management.add_profile.noprofiles")}</Typography></Grid>}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: "100%" }}>
              <Card style={{ ...cardStyle }}>
                <Grid conatiner p={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "none", width: "163px" }}
                      onClick={() => { navigate("/users") }}
                    >
                      {t("actions.cancel")}
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        width: "163px",
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                      onClick={handleSubmitPermission}
                      disabled={profile === null ? true : false}
                    >
                      {t("user_management.add_user.save")}
                      {perLoading && (
                        <CircularProgress
                          size={18}
                          style={{ color: "white" }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </>
      </Grid>
      <div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid container spacing={2} p={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="table_header">{t("user_management.add_profile.addtitle")}</Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography mb={1}>{t("user_management.add_profile.name")}</Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={(event) => setProfileName(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                sx={{ textTransform: "none", fontSize: "14px" }}
                onClick={createProfile}
                disabled={!profileName}
              >
                {t("user_management.add_profile.create")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleClose}
                sx={{ textTransform: "none", fontSize: "14px" }}
              >
                {t("actions.cancel")}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </div>
    </>
  );
};
export default Profiles;
