import { Grid } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { GrDocumentExcel } from "react-icons/gr";
import { AuthApis } from "../../service/auth/auth";

function Subdomain({ children }) {
  const [validDomain, setvalidDomain] = useState(null);

  useLayoutEffect(() => {
    AuthApis.validateSubdomain()
      .then((res) => setvalidDomain(true))
      .catch((err) => setvalidDomain(false));
  }, []);

  return (
    <>
      {validDomain !== null && validDomain && children}

      {validDomain !== null && !validDomain && (
        <Grid container justifyContent="center" alignItems="flex-end" p={"10%"}>
          <Grid item>
            <GrDocumentExcel size="50px" />
            <h2>This site can’t be reached</h2>
            <p>
              {" "}
              <b>{window.location.hostname.split("/")[0]}</b> refused to
              connect...
            </p>
            <p>Invalid URL !</p>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Subdomain;
