import React, { lazy } from "react";
import UsersList from "../views/workspaces/Users/Index";
import Profiles from "../views/workspaces/Profiles/Index";
import AddUser from "../views/workspaces/Users/addUser";
import { TbSettingsCog, TbUserPlus, TbUsersPlus, TbFileDollar, TbCirclePercentage, TbFileDescription, TbUsers, TbCalendarMonth, TbLogout, TbEmergencyBed, TbChecklist } from "react-icons/tb";
import { RiUserSettingsLine } from "react-icons/ri";
import { MdOutlineSettingsInputComposite } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { LiaFileContractSolid } from "react-icons/lia";
import { FiBox } from "react-icons/fi";

const Analytics = lazy(() => import("../views/workspaces/Dashboard"));
const TechnicianRegistration = lazy(() => import("../views/workspaces/Technicians/Index"));
const TechnicialDetails = lazy(() => import("../views/workspaces/Technicians/Details/Index"));
const AddTchnician = lazy(() => import("../views/workspaces/Technicians/Add/Index"));
const Customer_registration = lazy(() => import("../views/workspaces/Customer Registration/customerList"));
const Add_Client = lazy(() => import("../views/workspaces/Customer Registration/addClient"));
const Cilent_Details = lazy(() => import("../views/workspaces/Customer Registration/clientDetails"));
const Price_Table = lazy(() => import("../views/workspaces/PriceTable/Price_table"));
const AddPrice = lazy(() => import("../views/workspaces/PriceTable/Add_price"));
const Price_Details = lazy(() => import("../views/workspaces/PriceTable/Price_details"));
const Ticket_Management = lazy(() => import("../views/workspaces/Task Management/taskList"));
const AddTicket = lazy(() => import("../views/workspaces/Task Management/addTask"));
const TicketDetails = lazy(() => import("../views/workspaces/Task Management/ticketDetails"));
const Checklists = lazy(() => import("../views/workspaces/Checklists/checklist"));
const AddCheckList = lazy(() => import("../views/workspaces/Checklists/addChecklist"));
const CheckListDetails = lazy(() => import("../views/workspaces/Checklists/checklistDetails"));
const QuotationManagement = lazy(() => import("../views/workspaces/Quotation Management/quotationList"));
const AddQuotation = lazy(() => import("../views/workspaces/Quotation Management/addQuotation"));
const QuotationDetails = lazy(() => import("../views/workspaces/Quotation Management/quotationDetails"));
const Service_Orders = lazy(() => import("../views/workspaces/ServiceOrders/ServiceOrdersList"));
const AddServiceOrder = lazy(() => import("../views/workspaces/ServiceOrders/AddServiceOrder"));
const Service_Order_Details = lazy(() => import("../views/workspaces/ServiceOrders/ServiceOrderDetails"));
const ServiceList = lazy(() => import("../views/workspaces/Service Registration/serviceList"));
const AddService = lazy(() => import("../views/workspaces/Service Registration/addService"));
const ServiceDetails = lazy(() => import("../views/workspaces/Service Registration/serviceDetails"));
const EquipmentsList = lazy(() => import("../views/workspaces/Equipmments/index"));
const EquipmentsDetails = lazy(() => import("../views/workspaces/Equipmments/Details/Index"));
const AddEquipments = lazy(() => import("../views/workspaces/Equipmments/Add/Index"));
const Labour = lazy(() => import("../views/workspaces/Labour/laborList"));
const Contract = lazy(() => import("../views/workspaces/Contract/contractList"));
const AddContract = lazy(() => import("../views/workspaces/Contract/addContract"));
const ContractDetails = lazy(() => import("../views/workspaces/Contract/contractDetails"));
const TechnicianGeolocation = lazy(() => import("../views/workspaces/Geolocation/technicianGeolocation"));
const TechnicianCalender = lazy(() => import("../views/workspaces/Agenda/technicianAgenda"));
const ProductList = lazy(() => import("../views/workspaces/Supply Management/supply-list"));
const ProductDetails = lazy(() => import("../views/workspaces/Supply Management/supply-details"));



export const Admin = [
  {
    id: "ticket_management",
    title: "Ticket Management",
    type: "item",
    icon: <TbSettingsCog size={22} />,
    url: `/home`,
    breadcrumbs: true,
    element: <Ticket_Management />,
    children: [
      {
        id: "add_ticket",
        title: "Add Ticket",
        type: "item",
        url: "/ticket_management/add",
        breadcrumbs: true,
        element: <AddTicket />
      },
      {
        id: "ticket_detail",
        title: "Ticket Detail",
        type: "item",
        url: "/ticket_details",
        breadcrumbs: true,
        element: <TicketDetails />
      },
    ]
  },
  {
    id: "client_registration",
    title: "Client Registration",
    type: "item",
    icon: <TbUserPlus size={22} />,
    url: `/customer-registration`,
    breadcrumbs: true,
    element: <Customer_registration />,
    children: [
      {
        id: "add_client",
        title: "Add Client",
        type: "item",
        url: "/customer-registration/add",
        breadcrumbs: true,
        element: <Add_Client />
      },
      {
        id: "client_details",
        title: "Client Details",
        type: "item",
        url: "/customer-registration/details",
        breadcrumbs: true,
        element: <Cilent_Details />
      },
    ]
  },
  {
    id: "technician_registration",
    title: "Technician Registration",
    type: "item",
    icon: <TbUsersPlus size={22} />,
    url: "/technicians",
    breadcrumbs: true,
    element: <TechnicianRegistration />,
    children: [
      {
        id: "technicians_details",
        title: "Technician",
        type: "item",
        url: "/technicians/details",
        breadcrumbs: true,
        element: <TechnicialDetails />
      },
      {
        id: "technicians_add",
        title: "Technician",
        type: "item",
        url: "/technicians/add",
        breadcrumbs: true,
        element: <AddTchnician />
      },
    ]
  },
  {
    id: "service_registration",
    title: "Service Registration",
    type: "item",
    icon: <MdOutlineSettingsInputComposite size={22} />,
    url: "/service-registration",
    breadcrumbs: true,
    element: <ServiceList />,
    children: [
      {
        id: "service_details",
        title: "Service Details",
        type: "item",
        url: "/service-registration/details",
        breadcrumbs: true,
        element: <ServiceDetails />
      },
      {
        id: "add_service",
        title: "Add Service",
        type: "item",
        url: "/service-registration/add",
        breadcrumbs: true,
        element: <AddService />
      },
    ]
  },
  {
    id: "equipment_registration",
    title: "Equipment Registration",
    type: "item",
    icon: <TbEmergencyBed size={22} />,
    url: "/equipment-registration",
    breadcrumbs: true,
    element: <EquipmentsList />,
    children: [
      {
        id: "equipment_details",
        title: "Equipment",
        type: "item",
        url: "/equipment/details",
        breadcrumbs: true,
        element: <EquipmentsDetails />
      },
      {
        id: "equipment_add",
        title: "Equipment",
        type: "item",
        url: "/equipment/add",
        breadcrumbs: true,
        element: <AddEquipments />
      },
    ]
  },
  {
    id: "quotation_management",
    title: "Quotation Management",
    type: "item",
    icon: <TbFileDollar size={22} />,
    url: "/quotation-management",
    breadcrumbs: true,
    element: <QuotationManagement />,
    children: [
      {
        id: "add_quotation",
        title: "Add Quotation",
        type: "item",
        url: "/quotation-management/add",
        breadcrumbs: true,
        element: <AddQuotation />
      },
      {
        id: "quotation_details",
        title: "Quotation Details",
        type: "item",
        url: "/quotation-management/details",
        breadcrumbs: true,
        element: <QuotationDetails />
      },
    ]
  },
  {
    id: "supply_management",
    title: "Supply Manangement",
    type: "item",
    icon: <FiBox size={22} />,
    url: "/supply-management",
    breadcrumbs: true,
    element: <ProductList />,
    children: [
      {
        id: "price_details",
        title: "Price",
        type: "item",
        url: "/supply-management/details",
        breadcrumbs: true,
        element: <ProductDetails />
      },
    ]
  },
  {
    id: "price_table",
    title: "Price Table",
    type: "item",
    icon: <TbCirclePercentage size={22} />,
    url: "/price-table",
    breadcrumbs: true,
    element: <Price_Table />,
    children: [
      {
        id: "price_details",
        title: "Price",
        type: "item",
        url: "/price/details",
        breadcrumbs: true,
        element: <Price_Details />
      },
      {
        id: "price_add",
        title: "Price",
        type: "item",
        url: "/price/add",
        breadcrumbs: true,
        element: <AddPrice />
      },
    ]
  },
  {
    id: "service_order",
    title: "Service Orders",
    type: "item",
    icon: <TbFileDescription size={22} />,
    url: "/service-orders",
    breadcrumbs: true,
    element: <Service_Orders />,
    children: [
      {
        id: "service_order_details",
        title: "Service Orders",
        type: "item",
        url: "/service-orders/details",
        breadcrumbs: true,
        element: <Service_Order_Details />
      },
      {
        id: "service_order_add",
        title: "Service Orders",
        type: "item",
        url: "/service-orders/add",
        breadcrumbs: true,
        element: <AddServiceOrder />
      },
    ]
  },
  {
    id: "user_registration",
    title: "User Management",
    type: "item",
    icon: <RiUserSettingsLine size={22} />,
    url: "/users",
    breadcrumbs: true,
    element: <UsersList />,
    children: [
      {
        id: "users_list",
        title: "Profiles",
        type: "item",
        url: "/users/profiles",
        breadcrumbs: true,
        element: <Profiles />
      },
      {
        id: "add_user",
        title: "Add User",
        type: "item",
        url: "/users/add",
        breadcrumbs: true,
        element: <AddUser />
      },
    ]
  },
  {
    id: "checklist",
    title: "Checklists",
    type: "item",
    icon: <TbChecklist size={22} filled />,
    url: "/checklists",
    breadcrumbs: true,
    element: <Checklists />,
    children: [
      {
        id: "add_checklist",
        title: "Add Checklist",
        type: "item",
        url: "/checklists/add",
        breadcrumbs: true,
        element: <AddCheckList />
      },
      {
        id: "checklist_details",
        title: "Checklist details",
        type: "item",
        url: "/checklists/details",
        breadcrumbs: true,
        element: <CheckListDetails />
      },
    ]
  },
  {
    id: "ticket_management",
    title: "Ticket Management",
    type: "",
    icon: <TbSettingsCog size={22} />,
    url: `*`,
    breadcrumbs: true,
    element: <Analytics />,
  },
  {
    id: "labour_distribution",
    title: "Labour Distribution",
    type: "item",
    icon: <PiUsersThreeDuotone size={22} filled />,
    url: `/labour-distribution`,
    breadcrumbs: true,
    element: <Labour />
  },
  {
    id: "contract_management",
    title: "Contract Management",
    type: "item",
    icon: <LiaFileContractSolid size={22} filled />,
    url: `/contract-management`,
    breadcrumbs: true,
    element: <Contract />,
    children: [
      {
        id: "add_contract",
        title: "Add Contract",
        type: "item",
        url: "/contract-management/add",
        breadcrumbs: true,
        element: <AddContract />
      },
      {
        id: "contract_details",
        title: "Contract Details",
        type: "item",
        url: "/contract-management/details",
        breadcrumbs: true,
        element: <ContractDetails />
      },
    ]
  },
  {
    id: "technician_geolocation",
    title: "Technician Geolocation",
    type: "item",
    icon: <GrLocation size={22} filled />,
    breadcrumbs: true,
    url: "/technician-geolocation",
    breadcrumbs: true,
    element: <TechnicianGeolocation />
  },
  {
    id: "technician_agenda",
    title: "Technician Agenda",
    type: "item",
    icon: <TbCalendarMonth size={22} filled />,
    breadcrumbs: true,
    url: "/technician-agenda",
    breadcrumbs: true,
    element: <TechnicianCalender />
  },
  {
    id: "logout",
    title: "Logout",
    type: "logout",
    icon: <TbLogout size={22} />,
    breadcrumbs: true,
  },
];
