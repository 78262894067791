import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GrLanguage } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Grid,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { toggleDrawer } from "../../store/slices/drawerSlices";
import logoImage from "../../../src/assets/LogoFKN01.png";
import { getInitials } from "../../constant";

export default function Header() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const routesWithoutHeader = ["/login", "/", "*", ""];

  const { drawer } = useSelector((state) => state);
  const dispatch = useDispatch();
  let userName = JSON.parse(localStorage.getItem("user"))?.user?.name;

  const [anchorEl, setAnchorEl] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isLanguageMenuOpen = Boolean(languageAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleClickLanguage = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("selectedLanguage", lang);
    handleCloseLanguage();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const drawerHandle = () => {
    dispatch(toggleDrawer(true));
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Stack direction="row" spacing={2}>
          <Avatar>
            <NotificationsNoneOutlinedIcon />
          </Avatar>
        </Stack>
      </MenuItem>
    </Menu>
  );

  if (routesWithoutHeader.includes(location.pathname)) {
    return null;
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, position: "sticky", zIndex: 5 }}>
        <AppBar color="secondary">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={drawerHandle}
            >
              <MenuIcon />
            </IconButton>
            <img src={logoImage} alt="Your Image" style={{ height: "6%", width: "6%" }} />
            <Box sx={{ flexGrow: 0.1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {/* <Stack direction="row" spacing={2}>
                <Avatar sx={{ width: 30, height: 30 }}>
                  <NotificationsNoneOutlinedIcon />
                </Avatar>
              </Stack> */}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreIcon color="primary" />
              </IconButton>
            </Box>
            <Box>
              <Stack direction="row" spacing={1} ml={1}>
                <Stack>
                  <Avatar>{getInitials(userName)}</Avatar>
                </Stack>
                <Grid display="flex" alignItems="center" justifyContent="center">
                  <Typography>{userName}</Typography>
                </Grid>

                <Stack direction="row" spacing={2}>
                  <Avatar sx={{ width: 40, height: 40 }}>
                    <NotificationsNoneOutlinedIcon />
                  </Avatar>
                </Stack>
                <Stack>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <IconButton>
                      <GrLanguage onClick={handleClickLanguage} />
                    </IconButton>
                    <Menu
                      anchorEl={languageAnchorEl}
                      open={isLanguageMenuOpen}
                      onClose={handleCloseLanguage}
                    >
                      <MenuItem onClick={() => handleLanguageChange("en")}>
                        English
                      </MenuItem>
                      <MenuItem onClick={() => handleLanguageChange("pr")}>
                        Portuguese
                      </MenuItem>
                      <MenuItem onClick={() => handleLanguageChange("es")}>
                        Spanish
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Stack>
                <Stack sx={{ marginRight: 2 }}>
                  <IconButton>
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
}
