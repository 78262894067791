import React, { useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Stack, Typography, Grid, Card, IconButton } from "@mui/material";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

export default function CustomPagination({
  currentPage,
  setCurrentPage,
  count,
  data,
}) {

  useEffect(() => {
    if (currentPage === 0) {
      setCurrentPage(1);
    }
    if (data?.message === "No data available." && data?.previous) {
      setCurrentPage(currentPage - 1);
    }
  }, [data]);
  const itemsPerPage = 10;
  const total = Math.ceil(count / itemsPerPage);

  const handlePageChange = (event, page, increase) => {
    if (currentPage !== total && increase) setCurrentPage(page);
    else if (!increase && currentPage !== 1) {
      setCurrentPage(page);
    }
  };

  const handlePageChangebyCount = (event, page) => {
    setCurrentPage(page);
  };

  const Next = () => {
    console.log("in next ksjdnckds")
    return (
      <Card
        variant="outlined"
        sx={{
          p: "3px",
          display: "flex",
          alignItems: "center",
          width: "3vh",
          justifyContent: "center",
          cursor: "pointer",
          backgroundColor: "#F2F3F3",
          marginLeft: "10px"
        }}
        onClick={() => handlePageChange(null, currentPage + 1, true)}
      >
        <IconButton size="small" >
          <MdChevronRight size={20} color={"#272727"} />
        </IconButton>
      </Card>
    );
  };

  const Previous = () => {
    return (
      <Card
        variant="outlined"
        sx={{
          p: "3px",
          display: "flex",
          alignItems: "center",
          width: "3vh",
          justifyContent: "center",
          cursor: "pointer",
          backgroundColor: "#F2F3F3",
          marginRight: "10px"
        }}
        onClick={() => handlePageChange(null, currentPage - 1)}
      >
        <IconButton size="small" >
          <MdChevronLeft size={20} color={"#272727"} />
        </IconButton>
      </Card>
    );
  };

  return (
    <Grid
      mb={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      mr={2}
    >
      <Previous />
      <Stack spacing={2}>
        <Pagination
          count={total}
          page={currentPage <= 1 ? 1 : currentPage}
          variant="outlined"
          color="primary"
          shape="rounded"
          hideNextButton
          hidePrevButton
          renderItem={(item) => (
            <PaginationItem
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#48BB78 !important",
                  color: "#FFFF",
                },
              }}
              {...item}
              onClick={(e) => handlePageChangebyCount(e, item.page)}
            >
              {item.page === currentPage ? (
                <Typography style={{ fontWeight: "bold" }}>
                  {item.page}
                </Typography>
              ) : (
                item.page
              )}
            </PaginationItem>
          )}
          siblingCount={1}
          boundaryCount={1}
        />
      </Stack>
      <Next />
    </Grid>
  );
}
