
import Axios from 'axios';
class HttpClient {
  static instance = null;

  accessToken = '';

  refreshToken = '';

  _authHandler = null;

  _dispatch = null;

  userRole = null;

  tenant = '';

  static getInstance() {
    console.log('instance created')
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
      HttpClient.instance.tenant = HttpClient.instance.getTenantFromSubdomain();
    }
    return HttpClient.instance;
  }

  getTenantFromSubdomain = () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    return subdomain;
  }


  init = (state) => {
    console.log("gfhbjhnnhknjloinjoiunnbd")
    this.accessToken = state.tokens ? state.tokens?.accessToken : ''
    this.refreshToken = state.tokens ? state.tokens?.refreshToken : ''
    console.log('instace initccc')
    Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        console.log('errrorrrr ', error)
        if (error.code === "ERR_NETWORK") {
          return Promise.reject({
            'error': error?.message
          });
        }
        return Promise.reject(error);
      }
    );

  }

  prepareHeader = (headers = {}, authentication = true, token = null) => {
    console.log("tenent", this.tenant)
    const authHeaders = authentication ? { Authorization: `Bearer ${token ?? this.accessToken}` } : {};
    return { ...headers, ...authHeaders, 'X-Tenant': this.tenant };
  };

  get = async (url, params = {}, headers = {}) => {
    console.log('first')
    const { data } = await Axios.get(url, { params, headers: this.prepareHeader(headers) });
    console.log('first', data)
    return data;
  };

  post = async (url, body, params = {}, headers = {}, authentication = true, token = null) => {
    const { data } = await Axios.post(url, body, { params, headers: this.prepareHeader(headers, authentication) });
    return data;
  };

  put = async (url, body, params = {}, headers = {}, authentication = true, token = null) => {
    const { data } = await Axios.put(url, body, { params, headers: this.prepareHeader(headers, authentication, token) });
    return data;
  };

  delete = async (url, params = {}, headers = {}) => {
    const { data } = await Axios.delete(url, {
      params,
      headers: this.prepareHeader(headers),
    });
    return data;
  };

}

export default HttpClient.getInstance();
